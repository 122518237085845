import React from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "moment/locale/es";

export default function Index() {
  return (
    <div>
      <div class="container mx-auto relative ">
        <div className="mt-20 lg:mt-40 px-10 text-center lg:text-justify">
          <p className="text-3xl lg:text-5xl text-gray-700 font-bold mb-5 text-center">
            Términos y condiciones
          </p>
          <div className="text-gray-700 text-xl ">
            <p className="font-bold mb-5">INFORMACIÓN RELEVANTE</p>
            <p className="mb-5">
              Es requisito necesario para la adquisición de los servicios que se
              ofrecen en BE GLOBAL PRO S.A DE C.V, que lea y acepte los
              siguientes Términos y Condiciones que a continuación se redactan.
              El uso de nuestros servicios implicará que usted ha leído y
              aceptado los Términos y Condiciones de Uso en el presente
              documento. Todas los servicios que son ofrecidos por nuestro sitio
              web pudieran ser creadas, cobradas, enviadas o presentadas por una
              página web tercera y en tal caso estarían sujetas a sus propios
              Términos y Condiciones. En algunos casos, para adquirir un
              servicio, será necesario el registro por parte del usuario, con
              ingreso de datos personales fidedignos y definición de una
              contraseña. El usuario puede elegir y cambiar la clave para su
              acceso de administración de la cuenta en cualquier momento, en
              caso de que se haya registrado y que sea necesario para la
              solicitud de alguno de nuestros servicios.
            </p>
            <p className="font-bold mb-5">C L Á U S U L A S</p>

            <p className=" mb-5">
              <b>PRIMERA.</b> – <u>OBJETO</u>
            </p>
            <p className="mb-5">
              1.1 El presente contrato tiene por objeto la adquisición por parte
              de “EL USUARIO” una membresía para el acceso, uso y disfrute de
              los servicios establecidos por “LA EMPRESA”.
            </p>
            <p className=" mb-5">
              <b>SEGUNDA.</b> – <u>DESCRIPCIÓN DE LAS MEMBRESÍAS</u>
            </p>
            <p className="mb-5">
              2.1 La membresía adquirida por “EL USUARIO” es de modalidad
              [BÁSICA, COMPLETA, PLUS] y se encuentra sujeta a las prestaciones
              descritas en la siguiente cláusula.
            </p>
            <p className="mb-5">
              2.2 (BÁSICA) La membresía otorga a su titular el derecho de acceso
              a la plataforma educativa ofrecida por “LA EMPRESA”, incluyendo
              todos los cursos y módulos de está sin costo adicional alguno;
              acceso a las capacitaciones y webinars impartidas vía
              videoconferencia; acompañamiento personalizado únicamente vía
              mensajería por whatsapp, o correo electronico, no se da
              seguimiento si “EL USUARIO” envía audios, hace llamadas o pide
              videoconferencias personalizadas.
            </p>
            <p className="mb-5">
              2.2 (PLUS MENSUAL) La membresía otorga a su titular el derecho de
              acceso al sistema de dropshipping internacional y nacional; acceso
              a precios de mayoreo en la lista de productos ofrecidos por “LA
              EMPRESA”; acceso a la plataforma educativa ofrecida por “LA
              EMPRESA”, incluyendo todos los cursos y módulos de está sin costo
              adicional alguno; acceso a las capacitaciones y webinars
              impartidas vía videoconferencia; código de descuento para la
              obtención de hosting y dominio para sitio web con wordpress;
              acompañamiento personalizado conforme a las necesidades de “EL
              USUARIO”, no se da seguimiento si “EL USUARIO” envía audios, hace
              llamadas o pide videoconferencias personalizadas, solo se atiende
              vía mensajería por whatsapp o correo electrónico.
            </p>
            <p className="mb-5">
              2.2 (PLUS TRIMESTRAL) La membresía otorga a su titular el derecho
              de acceso al sistema de dropshipping internacional y nacional;
              acceso a precios de mayoreo en la lista de productos ofrecidos por
              “LA EMPRESA”; garantía en productos dropshipping; acceso a la
              plataforma educativa ofrecida por “LA EMPRESA”, incluyendo todos
              los cursos y módulos de está sin costo adicional alguno; acceso a
              las capacitaciones y webinars impartidas vía videoconferencia;
              código de descuento para la obtención de hosting y dominio para
              sitio web con wordpress; lista de proveedores confiables para
              Amazon y Mercado Libre; grupo de inversión en productos aplicables
              (no es un grupo de alguna red social); acompañamiento
              personalizado conforme a las necesidades de “EL USUARIO” no se da
              seguimiento si “EL USUARIO” envía audios, hace llamadas o pide
              videoconferencias personalizadas, solo se atiende vía mensajería
              por whatsapp o correo electrónico. .
            </p>
            <p className="mb-5">
              2.2.2 Para acceder a cualquiera de estas membresías es necesario
              llenar el formulario correspondiente que brindará “LA EMPRESA”;
              está podrá reservarse el derecho de admisión conforme a las
              respuestas brindadas. En caso de aceptar una membresía de forma
              errónea, se dará previo aviso a “EL USUARIO” y se le realizará la
              devolución de los montos liquidados de forma completa. Si eres
              aprobado tienes 3 horas para realizar el pago de la membresía o se
              cancela tu lugar dentro del sistema de manera definitiva, una vez
              que realices el pago previamente aprobada tu membresía no existe
              devolución o reembolso alguno.
            </p>
            <p className="mb-5">
              2.3 “LA EMPRESA” brindará a “EL USUARIO” un usuario y contraseña
              que le permitirá el acceso a los beneficios establecidos en la
              cláusula anterior.
            </p>
            <p className=" mb-5">
              <b>TERCERA.</b> – <u>LOS PAGOS Y SUS MODALIDADES</u>
            </p>
            <p className="mb-5">
              3.1 Para acceder a la membresía “EL USUARIO” realizará un pago
              inicial por el monto de:
            </p>
            <p className=" mb-5">
              <b>Membresía Básica:</b> $6,000 mxn ya con IVA.
            </p>
            <p className=" mb-5">
              <b>Membresía Plus Mensual:</b> $10,000 mxn ya con IVA.
            </p>
            <p className=" mb-5">
              <b>Membresía Plus Trimestral: </b> $17,000 mxn ya con IVA.
            </p>
            <p className="mb-5">3.2 El monto anterior cubre la cantidad de:</p>
            <p className=" mb-5">
              <b>Membresía Básica:</b> 30 días
            </p>
            <p className=" mb-5">
              <b>Membresía Plus Mensual:</b> 30 días
            </p>
            <p className=" mb-5">
              <b>Membresía Plus Trimestral: </b> 90 días
            </p>
            <p className="mb-5">
              Una vez finalizados la membresía tendrá un costo de
            </p>
            <p className=" mb-5">
              <b>Membresía Básica:</b> $3,000 MXN ya con IVA de manera mensual.
            </p>
            <p className=" mb-5">
              <b>Membresía Plus Mensual:</b>$5,000 MXN ya con IVA de manera
              mensual.
            </p>
            <p className=" mb-5">
              <b>Membresía Plus Trimestral: </b> $3,500 MXN ya con IVA de manera
              mensual.
            </p>
            <p className=" mb-5">
              <b>Membresía Plus Semestral: </b> $3,800 MXN. ya con IVA de manera
              mensual.
            </p>
            <p className=" mb-5">
              <b>Membresía Plus Anual: </b> $3,500 MXN ya con IVA de manera
              mensual.
            </p>
            <p className=" mb-5">
              3.4 El monto podrá ser cubierto en deposito en efectivo,
              transferencia bancaria o tarjeta de crédito.
            </p>
            <p className=" mb-5">
              3.5 Los depósitos y transferencias de deberán realizar en alguna
              de las siguientes cuentas bancarias autorizadas para membresías /
              reconsumos:{" "}
              <p className="font-bold">
                (En caso de requerir factura avisar antes de pagar porque la
                cuenta podría cambiar)
              </p>
            </p>

            <p className="mb-10">
              <table class="w-full  border-solid	 border-2 border-gray-400  border-spacing-y-12 border-collapse p-12">
                <thead></thead>
                <tbody>
                  <tr className="">
                    <td className="p-2 border-solid	 border-b-2 border-gray-400 font-bold pb-5">
                      No. de Cuenta
                    </td>
                    <td className="p-2  pb-5 border-solid	 border-b-2 border-gray-400">
                      190939860014
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2 border-solid	 border-b-2 border-gray-400 font-bold pb-5">
                      CLABE Interbancaria
                    </td>
                    <td className="p-2  pb-5 border-solid	 border-b-2 border-gray-400">
                      0587600001488656448
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2 border-solid	 border-b-2 border-gray-400 font-bold pb-5">
                      Banco
                    </td>
                    <td className="p-2  pb-5 border-solid	 border-b-2 border-gray-400">
                      BANREGIO
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2 border-solid	 border-b-2 border-gray-400 font-bold pb-5">
                      Titular
                    </td>
                    <td className="p-2  pb-5 border-solid	 border-b-2 border-gray-400">
                      MOD PACIFIC DISTRIBUCIONES SA DE CV
                    </td>
                  </tr>
                </tbody>
              </table>
            </p>

            <p className="mb-10">
              <table class="w-full  border-solid	 border-2 border-gray-400  border-spacing-y-12 border-collapse p-12">
                <thead></thead>
                <tbody>
                  <tr className="">
                    <td className="p-2 border-solid	 border-b-2 border-gray-400 font-bold pb-5">
                      No. de Cuenta
                    </td>
                    <td className="p-2  pb-5 border-solid	 border-b-2 border-gray-400">
                      0119466177
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2 border-solid	 border-b-2 border-gray-400 font-bold pb-5">
                      CLABE Interbancaria
                    </td>
                    <td className="p-2  pb-5 border-solid	 border-b-2 border-gray-400">
                      012760001194661776
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2 border-solid	 border-b-2 border-gray-400 font-bold pb-5">
                      Banco
                    </td>
                    <td className="p-2  pb-5 border-solid	 border-b-2 border-gray-400">
                      BBVA
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2 border-solid	 border-b-2 border-gray-400 font-bold pb-5">
                      Titular
                    </td>
                    <td className="p-2  pb-5 border-solid	 border-b-2 border-gray-400">
                      MOD PACIFIC DISTRIBUCIONES SA DE CV
                    </td>
                  </tr>
                </tbody>
              </table>
            </p>

            <p className="mb-10">
              <table class="w-full  border-solid	 border-2 border-gray-400  border-spacing-y-12 border-collapse p-12">
                <thead></thead>
                <tbody>
                  <tr className="">
                    <td
                      colSpan={2}
                      className="text-center p-2 border-solid	 border-b-2 border-gray-400 font-bold pb-5"
                    >
                      Transferencias y depósitos
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2 border-solid	 border-b-2 border-gray-400 font-bold pb-5">
                      Cuenta Clabe
                    </td>
                    <td className="p-2  pb-5 border-solid	 border-b-2 border-gray-400">
                      722969010468978303
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2 border-solid	 border-b-2 border-gray-400 font-bold pb-5">
                      Institucion
                    </td>
                    <td className="p-2  pb-5 border-solid	 border-b-2 border-gray-400">
                      Mercado Pago W
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2 border-solid	 border-b-2 border-gray-400 font-bold pb-5">
                      Nombre
                    </td>
                    <td className="p-2  pb-5 border-solid	 border-b-2 border-gray-400">
                      Allan Gerardo Martinez Armenta
                    </td>
                  </tr>
                </tbody>
              </table>
            </p>

            <p className=" mb-5">
              Una vez realizado el pago es necesario enviar foto del recibo,
              nombre completo, celular, correo, ciudad y estado al siguiente
              correo: admisiones@academiabeglobalpro.com en asunto colocar “PAGO
              MEMBRESÍA (Colocar la membresía seleccionada)” Adjunto debe
              encontrarse este contrato firmado en todas las hojas anexando una
              copia de tu identificacion oficial vigente y copia de comprobante
              de domicilio de maximo 2 meses de antigüedad, puedes solicitar
              este archivo en PDF descargable.
            </p>
            <p className=" mb-5">
              <b>CUARTA.</b> – <u>CONSIDERACIONES</u>
            </p>
            <p className=" mb-5">
              4.1 Para que “EL USUARIO” pueda adquirir de forma completa los
              beneficios que ofrece la membresía deberá asistir a las
              capacitaciones y reuniones predefinidas por “LA EMPRESA” y a su
              vez seguir la estrategia definida en ellas.
            </p>
            <p className=" mb-5">
              4.2 “LA EMPRESA” tiene reservado el derecho de revocar la
              membresía adquirida por “EL USUARIO” cuando se compruebe que este
              realizó comentarios negativos, difamatorios o contrarios a los
              valores que la empresa representa; cometió faltas de respeto,
              actitudes denigratorias o discrimine por cualquier razón a
              miembros del equipo de “LA EMPRESA” o a otros usuarios que forman
              parte de las membresías.
            </p>
            <p className=" mb-5">
              4.3 “EL USUARIO” puede acceder a un sistema de comisiones al
              ingresar nuevos usuarios a la membresía; para ingeresar a este
              sistema es necesario que “EL USUARIO” se encuentre dado de alta en
              Hacienda, pasar por varios examenes de admision y brindar a “LA
              EMPRESA” la siguiente documentación:
            </p>
            <div className="pl-2 lg:pl-6 ">
              <p className=" mb-5">
                <ol className="list-decimal">
                  <li> a) Identificación oficial con fotografía. </li>
                  <li>b) Constancia de situación fiscal. </li>
                  <li>c) Comprobante de domicilio vigente.</li>
                  <li>d) Copia de una identificacion oficial.</li>
                  <li>e) Dos referencias. </li>
                </ol>
              </p>
            </div>
            <p className=" mb-5">
              4.4 En caso de no contar con la documentación anterior al momento
              de acceder al sistema de comisiones, “LA EMPRESA” retendrá la
              comisión hasta que “EL USUARIO” cuente con la habilidad de emitir
              facturas. Cuando se retengan comisiones por más de 30 días, “EL
              USUARIO” será acreedor de una multa del 36% de las comisiones
              acumuladas.
            </p>
            <p className=" mb-5">
              4.5 En caso de existir fallas técnicas en las plataformas de “LA
              EMPRESA” se le otorgará a “EL USUARIO” días extra en su membresía
              correspondientes a la duración de los percances sucedidos.
            </p>
            <p className=" mb-5">
              4.6 En caso de que “EL USUARIO” tenga la intención de renovar su
              membresía pero no realice el pago en tiempo y forma, cuenta con 5
              días para realizar su pago sin cargo extra alguno. A partir del
              sexto día la renovación de su membresía tendrá un aumento del
              costo del 1% diario. Si se llegase acumular un mes y “EL USUARIO”
              aun tiene la intención de renovar su membresía, podrá realizarla
              con una penalización del 50% sobre el monto inicial mensual
              establecido. Una vez concurridos los tres meses sin realizar su
              pago tendrá que adquirir su membresía liquidando el monto total
              establecido en el momento en que deseé realizar la renovación.
            </p>

            <p className=" mb-5">
              4.7 Las membresías son intransferibles, en caso de sorprender a
              “EL USUARIO” compartiendo su usuario y contraseña con terceros se
              le revocará su membresía sin derecho a indemnización o devolución
              alguna.
            </p>
            <p className=" mb-5">
              4.8 Los resultados obtenidos dentro del SISTEMA BE GLOBAL PRO S.A.
              DE C.V son de la total responsabilidad del “USUARIO”, por ningún
              motivo la EMPRESA es responsable por no obtener los resultados
              esperados por falta de compromiso, acción y ejecución en los
              planes planteados, considerando que el desarrollo de un negocio no
              es de manera inmediata.
            </p>
            <p className=" mb-5">
              <b>QUINTA.</b> – <u>CONFIDENCIALIDAD</u>
            </p>
            <p className=" mb-5">
              5.1 “LA EMPRESA” posiblemente requiera de información adicional
              para brindar un servicio preciso y completo. Esta información y
              documentación se considerará como “INFORMACIÓN CONFIDENCIAL”.{" "}
            </p>

            <p className=" mb-5">
              5.2 “La “INFORMACIÓN CONFIDENCIAL” deberá ser manejada y tratada
              por los “RECEPTORES”, sus directores, oficiales, agentes,
              empleados, asesores y representantes con la mayor y estricta
              confidencialidad y por ningún motivo deberá ser divulgada o
              utilizada de manera distinta al propósito por el cual fue
              revelada.
            </p>

            <p className=" mb-5">
              5.3 La anterior obligación no será aplicable a la información
              donde el “RECEPTOR” pueda demostrar que: (a) estaba en el dominio
              público al momento de establecer los términos aquí mencionados, o
              (b) estaba en su poder antes de la revelación de esta por parte
              del “REVELADOR” o bien (c) fue adquirida de un tercero que tiene
              el derecho legal de revelar la información.
            </p>
            <p className=" mb-5">
              <b>SEXTA.</b> – <u>LEY APLICABLE, JURISDICCIÓN, REGISTRO</u>
            </p>
            <p className=" mb-5">
              6.1 Para todo lo relacionado con la interpretación, cumplimiento y
              ejecución del presente Contrato, las partes se someten a las leyes
              aplicables y tribunales competentes de la Ciudad Hermosillo,
              Sonora, México, renunciando expresamente a la competencia de
              cualquier otro fuero o jurisdicción que pudiere corresponderles en
              virtud de sus domicilios presentes o futuros o por cualquier otra
              causa.
            </p>
            <p className=" mb-5">
              <b>SÉPTIMA.</b> – <u>ACUERDO TOTAL</u>
            </p>
            <p className=" mb-5">
              7.1 El presente Convenio contiene el acuerdo total entre las
              partes respecto de su objeto y deja sin efecto cualquier acuerdo o
              negociación anterior, sea verbal o escrito, que las partes hayan
              celebrado con anterioridad al presente Contrato.{" "}
            </p>
            <p className=" mb-5">
              <b>OCTAVA.</b> – <u>ENCABEZADOS</u>
            </p>
            <p className=" mb-5">
              8.1 Los encabezados en cada una de las Cláusulas del presente
              Contrato serán usados únicamente como referencia y no para efectos
              de interpretación. Es únicamente el texto expreso de cada Cláusula
              lo que se considerará para propósitos de determinar las
              obligaciones de cada parte de conformidad con el presente
              instrumento.
            </p>
            <p className=" mb-5">
              <b>NOVENA.</b> – <u>INDEPENDENCIA DE LAS CLÁUSULAS</u>
            </p>
            <p className=" mb-5">
              9.1 Cada cláusula, párrafo, parte, término y/o estipulaciones de
              este Contrato, se considerarán independientes entre sí, y si por
              alguna razón, cualquiera de ellas se determina por un tribunal de
              jurisdicción competente como inválida o contraria a, o en
              conflicto con, cualquier ley o reglamento existente o futuro, no
              perjudicará o afectará las demás cláusulas, partes y
              estipulaciones remanentes de este contrato, y éstos continuaran
              con fuerza legal y efectos plenos y obligarán a las partes del
              presente instrumento.{" "}
            </p>
            <p className=" mb-5 font-bold">
              SE MANIFIESTAN QUE EN LA CELEBRACIÓN DE LOS PRESENTES TERMINOS Y
              CONDICIONES NO EXISTE ERROR, DOLO, MALA FE, VIOLENCIA FÍSICA O
              MORAL, DE ALGUNA DE ELLAS QUE PUDIERA VICIAR SU CONSENTIMIENTO.
            </p>
            <p className=" mb-5 font-bold">SISTEMA BE GLOBAL PRO S.A DE C.V </p>
            <p className=" mb-5 font-bold">ACTUALIZADO JUNIO 2023</p>
          </div>
        </div>
      </div>
    </div>
  );
}
